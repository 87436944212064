
import { AfterViewInit, ChangeDetectionStrategy, Component, CUSTOM_ELEMENTS_SCHEMA, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AmplitudeEventService } from 'src/app/core/services/amplitude/amplitude-event.service';
import { StartApplicationFormComponent } from '../start-application-form/start-application-form.component';
import { PortalCookies } from 'src/app/shared/constants/browser-cookie.constants';
import { SessionStoreService } from 'src/app/core/services/stores/session-store.service';

@Component({
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  selector: 'app-integrations-announcement',
  standalone: true,
  imports: [
    MatButtonModule
  ],
  templateUrl: './integrations-announcement.component.html',
  styleUrls: ['./integrations-announcement.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IntegrationsAnnouncementComponent implements OnInit, AfterViewInit {
  constructor(
    private dialogRef: MatDialogRef<IntegrationsAnnouncementComponent>,
    private dialog: MatDialog,
    private amplitudeEventService: AmplitudeEventService,
    private sessionStore: SessionStoreService,
  ) { }

  lottieScript = '/assets/lottie/integrations.json';
  featureList: { icon: string; description: string }[] = [
    {
      icon: 'star',
      description: 'With a few clicks, you can now easily connect your CRM account through the Agent Portal.'
    },
    {
      icon: 'star',
      description: 'Once connected, client information from your CRM can be pre-filled directly in Firelight or iGo.'
    },
    {
      icon: 'star',
      description: 'We\'re exploring other integration opportunities to elevate your experience. Stay tuned for exciting updates!'
    },
  ];
  hasSeenModal = false;

  ngOnInit(): void {
    this.checkSeenModalStatus();
    this.dialogRef.afterClosed().subscribe({
      next: () => {
        localStorage.setItem(PortalCookies.crmIntegrations, this.hasSeenModal.toString());
      }
    });
  }

  ngAfterViewInit(): void {
    this.dialogRef.updateSize('108rem', '64rem');
  }

  checkSeenModalStatus(): void {
    this.hasSeenModal = !!localStorage.getItem(PortalCookies.crmIntegrations);
  }

  openStartEAppModal(): void {
    const startTour = !this.hasSeenModal && (!this.sessionStore.IsSalesforceUser && !this.sessionStore.IsWealthboxUser && !this.sessionStore.User.IsRedtailUser);
    this.dialog.open<StartApplicationFormComponent, { isModalView?: boolean, startTour?: boolean }>(StartApplicationFormComponent, {
      data: {
        isModalView: true,
        startTour: startTour,
      }
    });
    this.hasSeenModal = true;
    this.dialogRef.close(this.hasSeenModal);
    this.amplitudeEventService.logClickEvent('Start E-App from Announcement');
  }
}
