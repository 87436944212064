<section class="grid h-full grid-cols-2">
  <lottie-player class="h-auto col-span-1"
    src="/assets/lottie/integrations.json"
    background="#187EEB"
    loop autoplay>
  </lottie-player>
  <section class="flex flex-col gap-y-30 p-30">
    <span class="flex items-center justify-center w-48 h-48 mx-auto bg-blue-100 rounded-full">
      <span class="w-24 h-24 bg-blue-500 rounded-full"></span>
    </span>
    <div class="flex flex-col items-center text-center gap-y-5">
      <h1 class="text-3xl font-bold">Introducing CRM Integrations</h1>
      <h2 class="text-xl font-bold">Including Redtail, Salesforce, and Wealthbox</h2>
    </div>
    <div class="flex flex-col mt-auto rounded-t -mb-30 p-30 min-h-384 bg-blue-50 gap-y-40">
      <ul class="flex flex-col gap-y-20">
        @for (item of featureList; track item) {
          <li class="flex items-center gap-x-20">
            <span class="text-blue-500 material-icons">{{item.icon}}</span>
            <p class="text-lg">{{item.description}}</p>
          </li>
        }
      </ul>
      <button type="button" mat-button class="mx-auto button is-lg w-320 is-primary" (click)="openStartEAppModal()">Get Started!</button>
    </div>
  </section>
</section>