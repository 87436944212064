<form [formGroup]="pointAdjustmentForm" (ngSubmit)="onSubmit()" class="flex flex-col flex-grow w-full h-full overflow-auto">
  <section class="flex items-center flex-none p-10 border-b border-solid border-gray-lighter lg:p-20">
    <h1 class="m-10 text-xl font-bold" appCyData="activeStep">
      @if (activeStep === 0) {
        <span>Submit Marketing Expenses</span>
      }@else if (activeStep  > 0 && activeStep < 5) {
        <span>Step {{activeStep}}</span>
      }@else if (activeStep === 5) {
        <span>Request Submitted!</span>
      }
    </h1>
    <button mat-button tabindex="-1" type="button" mat-dialog-close
      class="m-10 ml-auto icon-button is-primary is-inverted"><i class="material-icons">close</i></button>
  </section>
  <section class="flex flex-col flex-grow p-10 overflow-auto text-base lg:p-20">
    @switch (activeStep){
      @case (0) {
        <section class="flex flex-col">
          <p class="m-10 text-lg mb-30">Please fill out this form to submit a request to use your marketing dollars
          for marketing expenses.</p>
          <div
            class="p-20 m-10 my-20 leading-snug text-left bg-opacity-25 border border-solid rounded border-blue-lighter bg-blue-lighter">
            <div class="flex items-center justify-start mb-20">
              <i class="mr-10 fig-24px fig-info-circle text-gray"></i>
              <span class="font-semibold">Quick tips:</span>
            </div>
            <p class="py-5 mb-20"><em>- All fields are required unless noted otherwise.</em></p>
            <p class="py-5"><em>- Please submit one request per vendor. Any requests with multiple vendors will be
            rejected until submitted correctly.</em></p>
            <p class="py-5"><em>- Please have the invoice receipt available as you will be required to upload
            it.</em></p>
            <p class="py-5 mb-20"><em>- You will receive a confirmation email once the request has been approved or
            denied.</em></p>
            <p class="py-5">
              <a class="font-semibold underline text-blue-dark hover:text-blue-900" target="_blank"
              href="/v1/Documents/How_to_Submit_A_Points_Adjustment.pdf" appCyData="howToGuide">
              Click here</a> for our 'How To' guide for submitting marketing expenses.
            </p>
          </div>
        </section>
      }
      @case (1) {
        <section class="flex flex-col">
          <div class="flex flex-col p-10">
            <span class="mb-10 text-sm font-medium label text-gray">Marketing Dollar Balance</span>
            <h2 class="text-lg font-bold"
              [ngClass]="agentMarketingDollar.Entry.MarketingDollarsTotal < 0 ? 'text-orange' : 'text-green'"
              appCyData="marketingDollarBalance">
            {{agentMarketingDollar.Entry.MarketingDollarsTotal | currency: '$'}}</h2>
          </div>
          <div class="flex flex-col p-10">
            <label for="purchaseCategory" class="mb-10 text-sm font-medium text-gray required-label">Purchase
            Category</label>
            <div class="mb-10 text-sm font-medium text-gray">
              <p class="py-5"><a class="font-semibold underline text-blue-dark hover:text-blue-900" target="_blank"
              href="https://www.figmarketing.com/files/Marketing_Expense_Outline.pdf" appCyData="expenseOutline">Click here</a> for a list of qualified and unqualified purchases.</p>
            </div>
            <mat-select formControlName="purchaseCategory" name="purchaseCategory" class="select"
              placeholder="Select a purchase category"
              appCyData="purchaseCategory">
              @for (category of purchaseCategories; track category) {
                <mat-option [value]="category.Id">
                  {{category.Title}}
                </mat-option>
              }
            </mat-select>
            @if (pointAdjustmentForm.controls.purchaseCategory.touched && pointAdjustmentForm.controls.purchaseCategory.invalid) {
              <div
                class="mt-5 text-sm text-red">
                @if (pointAdjustmentForm.controls.purchaseCategory.errors?.required) {
                  <div>This field is required
                  </div>
                }
              </div>
            }
          </div>
        </section>
      }
      @case (2) {
        <section class="flex flex-col">
          <div class="flex flex-col p-10">
            <label [for]="remainingBalance" class="mb-10 text-sm font-medium text-gray">Remaining Balance</label>
            <h2 class="text-lg font-bold" [ngClass]="remainingBalance < 0 ? 'text-orange' : 'text-green'">
            {{remainingBalance | currency: '$'}}</h2>
          </div>
          <div class="flex flex-col p-10">
            <label for="invoiceAmount" class="mb-10 text-sm font-medium text-gray required-label">Invoice
            Amount</label>
            <input formControlName="invoiceAmount" name="invoiceAmount" class="input" currencyMask
              placeholder="$0.00" (blur)="updateRemainingBalance()"
              appCyData="invoiceAmount" />
            @if (pointAdjustmentForm.controls.invoiceAmount.touched && pointAdjustmentForm.controls.invoiceAmount.invalid) {
              <div
                class="mt-5 text-sm text-red">
                @if (pointAdjustmentForm.controls.invoiceAmount.errors?.required) {
                  <div>This field is required
                  </div>
                }
              </div>
            }
          </div>
          <div class="flex flex-col p-10">
            <label for="purchaseCategory" class="mb-10 text-sm font-medium text-gray required-label">Payment
            Type</label>
            <mat-select formControlName="paymentType" name="purchaseCategory" class="select"
              placeholder="Select a payment type" appCyData="paymentType">
              @for (type of paymentTypes; track type) {
                <mat-option [value]="type.Id">
                  {{type.Title}}
                </mat-option>
              }
            </mat-select>
            @if (pointAdjustmentForm.controls.paymentType.touched && pointAdjustmentForm.controls.paymentType.invalid) {
              <div
                class="mt-5 text-sm text-red">
                @if (pointAdjustmentForm.controls.paymentType.errors?.required) {
                  <div>This field is required
                  </div>
                }
              </div>
            }
          </div>
          @switch (pointAdjustmentForm.controls.paymentType.value){
            <!-- Check -->
            @case (1) {
              <div formGroupName="paymentTypeCheck"
                class="flex flex-col">
                <div class="flex flex-col p-10">
                  <label for="vendorName" class="mb-10 text-sm font-medium text-gray required-label">Make Payable
                  To</label>
                  <input formControlName="vendorName" name="vendorName" class="input" placeholder="Enter a value" appCyData="vendorNameInput" />
                  @if (pointAdjustmentForm.controls.paymentTypeCheck.controls.vendorName.touched && pointAdjustmentForm.controls.paymentTypeCheck.controls.vendorName.invalid) {
                    <div
                      class="mt-5 text-sm text-red">
                      @if (pointAdjustmentForm.controls.paymentTypeCheck.controls.vendorName.errors?.required) {
                        <div>
                        This field is required</div>
                      }
                    </div>
                  }
                </div>
                <div class="flex flex-col p-10">
                  <label for="vendorStreetAddress" class="mb-10 text-sm font-medium text-gray required-label">Vendor's
                  Street Address</label>
                  <input formControlName="vendorStreetAddress" name="vendorStreetAddress" class="input"
                    placeholder="Enter street address"
                    appCyData="vendorStreetAddress" />
                  @if (pointAdjustmentForm.controls.paymentTypeCheck.controls.vendorStreetAddress.touched && pointAdjustmentForm.controls.paymentTypeCheck.controls.vendorStreetAddress.invalid) {
                    <div
                      class="mt-5 text-sm text-red">
                      @if (pointAdjustmentForm.controls.paymentTypeCheck.controls.vendorStreetAddress.errors?.required) {
                        <div
                          >
                        This field is required</div>
                      }
                    </div>
                  }
                </div>
                <div class="flex flex-col p-10">
                  <label for="vendorCity" class="mb-10 text-sm font-medium text-gray required-label">Vendor's
                  City</label>
                  <input formControlName="vendorCity" name="vendorCity" class="input" placeholder="Enter a value"
                    appCyData="vendorCity" />
                  @if (pointAdjustmentForm.controls.paymentTypeCheck.controls.vendorCity.touched && pointAdjustmentForm.controls.paymentTypeCheck.controls.vendorCity.invalid) {
                    <div
                      class="mt-5 text-sm text-red">
                      @if (pointAdjustmentForm.controls.paymentTypeCheck.controls.vendorCity.errors?.required) {
                        <div>
                        This field is required</div>
                      }
                    </div>
                  }
                </div>
                <div class="flex flex-wrap">
                  <div class="flex flex-col p-10 sm:w-1/2">
                    <label for="vendorState" class="mb-10 text-sm font-medium text-gray required-label">Vendor's
                    State</label>
                    <mat-select formControlName="vendorState" name="vendorState" class="select"
                      placeholder="Select a state"
                      appCyData="vendorState">
                      @for (state of states; track state) {
                        <mat-option [value]="state">
                          {{state.StateName}}
                        </mat-option>
                      }
                    </mat-select>
                    @if (pointAdjustmentForm.controls.paymentTypeCheck.controls.vendorState.touched && pointAdjustmentForm.controls.paymentTypeCheck.controls.vendorState.invalid) {
                      <div
                        class="mt-5 text-sm text-red">
                        @if (pointAdjustmentForm.controls.paymentTypeCheck.controls.vendorState.errors?.required) {
                          <div
                            >
                          This field is required</div>
                        }
                      </div>
                    }
                  </div>
                  <div class="flex flex-col p-10 sm:w-1/2">
                    <label for="vendorZip" class="mb-10 text-sm font-medium text-gray required-label">Vendor's
                    Zip Code</label>
                    <input formControlName="vendorZip" name="vendorZip" class="input" placeholder="Enter a value"
                      appCyData="vendorZip" />
                    @if (pointAdjustmentForm.controls.paymentTypeCheck.controls.vendorZip.touched && pointAdjustmentForm.controls.paymentTypeCheck.controls.vendorZip.invalid) {
                      <div
                        class="mt-5 text-sm text-red">
                        @if (pointAdjustmentForm.controls.paymentTypeCheck.controls.vendorZip.errors?.required) {
                          <div
                            >
                          This field is required</div>
                        }
                      </div>
                    }
                  </div>
                </div>
              </div>
            }
            <!-- ACH -->
            @case (5) {
              <div formGroupName="paymentTypeAch"
                class="flex flex-col p-10">
                <label for="vendorName" class="mb-10 text-sm font-medium text-gray required-label">Vendor Name</label>
                <mat-select formControlName="vendorName" name="vendorName" class="select"
                  placeholder="Select a vendor name"
                  appCyData="vendorNameSelect">
                  @for (vendor of vendors; track vendor) {
                    <mat-option [value]="vendor.Vendor">
                      {{vendor.Vendor}}
                    </mat-option>
                  }
                </mat-select>
                @if (pointAdjustmentForm.controls.paymentTypeAch.controls.vendorName.touched && pointAdjustmentForm.controls.paymentTypeAch.controls.vendorName.invalid) {
                  <div
                    class="mt-5 text-sm text-red">
                    @if (pointAdjustmentForm.controls.paymentTypeAch.controls.vendorName.errors?.required) {
                      <div>
                      This field is required</div>
                    }
                  </div>
                }
              </div>
            }
            <!-- Other -->
            @case (2005) {
              <div formGroupName="paymentTypeOther"
                class="flex flex-col p-10">
                <label for="paymentTypeName" class="mb-10 text-sm font-medium text-gray required-label">Specify Payment
                Type</label>
                <textarea formControlName="paymentTypeName" name="paymentTypeName" class="textarea" rows="6"
                placeholder="Specify preferred payment type" appCyData="paymentTypeName"></textarea>
                @if (pointAdjustmentForm.controls.paymentTypeOther.controls.paymentTypeName.touched && pointAdjustmentForm.controls.paymentTypeOther.controls.paymentTypeName.invalid) {
                  <div
                    class="mt-5 text-sm text-red">
                    @if (pointAdjustmentForm.controls.paymentTypeOther.controls.paymentTypeName.errors?.required) {
                      <div
                        >
                      This field is required</div>
                    }
                  </div>
                }
              </div>
            }
          }
        </section>
      }
      @case (3) {
        <section class="flex flex-col">
          <div class="flex flex-col p-10">
            <label for="contactEmail" class="mb-10 text-sm font-medium text-gray required-label">Contact
            Email</label>
            <input appCyData="contactEmail" formControlName="contactEmail" name="contactEmail" class="input" type="text"
              placeholder="Enter an email address" />
            @if (pointAdjustmentForm.controls.contactEmail.touched && pointAdjustmentForm.controls.contactEmail.invalid) {
              <div
                class="mt-5 text-sm text-red">
                @if (pointAdjustmentForm.controls.contactEmail.errors?.required) {
                  <div>This field is required</div>
                }
                @if (pointAdjustmentForm.controls.contactEmail.errors?.email) {
                  <div>This field is required</div>
                }
              </div>
            }
          </div>
          <div class="flex flex-col p-10">
            <label for="notes" class="mb-10 text-sm font-medium text-gray">Notes & Memos (Optional)</label>
            <textarea appCyData="notes" formControlName="notes" name="notes" class="textarea" rows="6"
            placeholder="Provide additional information"></textarea>
          </div>
        </section>
      }
      @case (4) {
        <section class="flex flex-col">
          <div class="flex flex-col p-10">
            <label for="fileUploads" class="mb-10 text-sm font-medium text-gray required-label">Upload Invoice
            Receipt</label>
            <div class="relative flex flex-col items-center justify-center p-20 text-center transition duration-150 border-2 border-dashed rounded cursor-pointer border-gray-lighter min-h-128 hover:text-blue hover:border-blue text-gray"
              [class]="dragover? 'border-blue text-blue':''">
              <input appCyData="fileUploads" formControlName="fileUploads" name="fileUploads" class="absolute top-0 left-0 w-full h-full opacity-0" accept=".pdf,.png,.jpg,.jpeg"
                (dragover)="dragOverHandler($event)" type="file" multiple id="file"
                (change)="onFileInput($event)">
              <span class="text-sm font-semibold label">Click or drag files here to upload</span>
              <span class="text-sm font-semibold label">PDF, PNG, JPG</span>
            </div>
            <!-- <input formControlName="fileUploads" name="fileUploads" class="h-auto p-10 input" type="file" multitple
            (change)="onFileInput($event)" placeholder="Enter an email address" /> -->
            @if (pointAdjustmentForm.controls.fileUploads.touched && pointAdjustmentForm.controls.fileUploads.invalid) {
              <div
                class="mt-5 text-sm text-red">
                @if (pointAdjustmentForm.controls.fileUploads.errors?.required) {
                  <div>This field is required</div>
                }
              </div>
            }
          </div>
          @if (filesToUpload.length) {
            <div class="flex flex-wrap flex-none p-10 mt-10">
              @for (file of filesToUpload; track file) {
                <div class="flex items-center w-full p-10">
                  <p class="mr-10">{{file.name}}</p>
                  <button class="ml-auto icon-button is-warning is-inverted is-sm" aria-label="Delete file button"
                    type="button" (click)="removeFile(file)">
                    <span class="material-icons mi-18">close</span>
                  </button>
                </div>
              }
            </div>
          }
        </section>
      }
      @case (5) {
        <section class="flex flex-col">
          <div class="flex flex-col items-center justify-center flex-grow p-20 m-10">
            <i class="m-20 fig-48px fig-check-circle-filled text-green"></i>
            <p class="text-lg text-center">
              Thank You! You will receive an email confirmation once the request has been reviewed.
            </p>
          </div>
        </section>
      }
    }
  </section>
  <section class="flex flex-none p-10 border-t border-solid lg:p-20 border-gray-lighter">
    @if (activeStep !== 5) {
      <button type="button" mat-button class="m-10 mr-auto button is-primary is-outlined is-lg"
        (click)="prevStep()" [disabled]="isUploading || isSubmitting"
        appCyData="backOrClose">
        {{activeStep === 0 ? 'Close' : 'Back'}}
      </button>
    }
    @if (activeStep < 4) {
      <!-- TODO: setup per-step validation: refactor -->
      @if (activeStep !== 2 || remainingBalance >= 0 || proceedNegative) {
        <button type="button" mat-button class="m-10 ml-auto button is-primary is-lg"
        (click)="nextStep()" [disabled]="isUploading || isSubmitting
        || (activeStep === 1 && pointAdjustmentForm.controls.purchaseCategory.invalid)
        || (activeStep === 2 && (pointAdjustmentForm.controls.invoiceAmount.invalid || pointAdjustmentForm.controls.paymentType.invalid))
        || (activeStep === 3 && pointAdjustmentForm.controls.contactEmail.invalid)"
          appCyData="next">
          Next
        </button>
      }
      @if ( activeStep === 2 && remainingBalance < 0 && !proceedNegative) {
        <button type="button" mat-button class="m-10 button is-primary is-lg"
        (click)="openNegativeConfirmation()" [disabled]="isUploading
        || isSubmitting || pointAdjustmentForm.controls.invoiceAmount.invalid
        || pointAdjustmentForm.controls.paymentType.invalid
        || pointAdjustmentForm.controls.paymentTypeCheck.invalid
        || pointAdjustmentForm.controls.paymentTypeAch.invalid
        || pointAdjustmentForm.controls.paymentTypeOther.invalid">
          Next
        </button>
      }
    }
    @if (activeStep === 4) {
      <button appCyData="submitBtn" type="submit" mat-button class="m-10 ml-auto button is-primary is-lg"
        [disabled]="isUploading || isSubmitting || pointAdjustmentForm.invalid">
        {{isSubmitting ? 'Submitting' : ' Submit'}}
      </button>
    }
    @if (activeStep === 5) {
      <button appCyData="closeModal" type="button" mat-button mat-dialog-close="true" class="m-10 ml-auto button is-primary is-lg"
        [disabled]="isUploading || isSubmitting || pointAdjustmentForm.invalid">
        Close
      </button>
    }
  </section>
</form>