import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CPCMonthlyBreakdown, CpcModel } from 'src/app/shared/models/cpc.models';
import { EntryResponse } from 'src/app/shared/models/generic.models';

@Injectable({
  providedIn: 'root'
})
export class CpcApiService {

  constructor(private http: HttpClient) { }

  getUserCPCs(startDate: Date, endDate: Date): Observable<CpcModel> {
    return this.http.get<EntryResponse<CpcModel>>(`/api/CPCs?start=${startDate.toUTCString()}&end=${endDate.toUTCString()}`)
      .pipe(map((x: EntryResponse<CpcModel>) => x.Entry));
  }

  /**
   * Return year & production breakdown by month based on the selected year
   * @param year Selected year to return data
   * @returns Monthly breakdown model
   */
  getCPCMonthlyBreakdownByYear(year: number): Observable<CPCMonthlyBreakdown> {
    return this.http.get<CPCMonthlyBreakdown>(`/api/Policies/Cpcs/MonthlyBreakdown/${year}`);
  }
}

